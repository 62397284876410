import Vuex      from 'vuex'


// This silliness is needed because the vuex-preload element isn't rendered by
// the time this code executes.
//
// To compensate, the data variable is declared here
// at the top of this file's scope - the data is set up when the DOM is loaded.
// Then, this data is used when the store() function is called.
// const store = new Vuex.Store({
//   state,
//   mutations,
//   actions
// })
let data;

document.addEventListener('DOMContentLoaded', () => {
  data = document.getElementById('vuex-preload')
  data = JSON.parse(data.getAttribute('data'))
})

export function store() {
  return new Vuex.Store({
    state: {
      bioData:  data.bioData,
      is_admin: false
    },

    mutations: {
    },

    actions: {
    }
  })
}
