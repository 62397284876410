<!-- Very simple component that grabs focus on page load -->
<template>
  <input v-bind="$attrs" />
</template>

<script>
  export default {
    mounted() {
      this.$el.focus()
    }
  }
</script>
